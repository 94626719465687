import FormatContentSlider from '~/components/Molecules/Organic/FormatContentSlider/FormatContentSlider.vue'
import device from '~/mixins/device.js'

export default {
  mixins: [device],

  components: {
    hotOrganicTabs: () => import('~/components/Molecules/Organic/Tabs/Tabs.vue'),
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: true,
    },

    button: {
      type: Object,
      required: true,
    },

    imageDefault: {
      type: Object,
      required: true,
    },

    tabs: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formatContentSlider: FormatContentSlider,
      swiperOptions: {
        spaceBetween: 36,
        slidesPerView: 1,
        speed: 1000,
        allowTouchMove: true,
        normalizeSlideIndex: false,
        centeredSlides: false,
        /*autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },*/
        breakpoints: {
          768: {
            slidesPerView: 1.5,
            spaceBetween: 32,
          },
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          el: '.swiper-navigation',
          clickable: true,
        },
      },
      selectedTab: 0,
      activeTab: 0,
    }
  },

  computed: {
    accordionClasses() {
      const routePath = this.$route.path
      const locale = this.$i18n.locale
      if (routePath === '/en' && locale === 'en') return 'type--en'

      return false
    },
  },

  methods: {
    toggle(i) {
      this.activeTab = i
    },
  },
}
